/* eslint-disable no-case-declarations */
import React, { useCallback, useEffect, useState } from "react";
import { useLoads } from "react-loads";
import BlogPost from "../templates/blogPost";
import queryString from "query-string";
import sanityClient from "../lib/sanityClientPreview";

const PreviewPage = () => {
  let documentId,
    documentType = null;
  if (typeof window !== "undefined") {
    const query = queryString.parse(window.location.search);
    documentId = query.documentId;
    documentType = query.documentType;
  }

  const [documentVersion, setDocumentVersion] = useState(0);

  const handlePreviewFetch = useCallback(async () => {
    switch (documentType) {
      case "post":
        const [data] = await sanityClient.fetch(`
        *[_id == "${documentId}"]  {
          ...,
          authors[]->{
            ...,
            slug{
              ...
            }
          },
          categories[]->
        }`);
        return data;
      default:
        console.error("UNKNOWN DOCUMENT TYPE OF: ", documentType);
        break;
    }
  }, [documentId, documentType]);

  const {
    error,
    isResolved,
    response: sanityDocument,
    load,
  } = useLoads("handlePreviewFetch", handlePreviewFetch, {
    variables: [documentVersion],
    debounce: 2000,
  });

  useEffect(() => {
    // if this page is inside Sanity iframe, listen for events from Sanity about document updates
    function messageListener(event) {
      if (
        !event.origin.startsWith("http://localhost") &&
        !event.origin.startsWith("https://primary-vc.sanity.studio/")
      ) {
        return;
      }

      if (event.data !== "sanity-document-update") {
        return;
      }

      setDocumentVersion((version) => version + 1);
    }

    window.addEventListener("message", messageListener, false);
    return () => window.removeEventListener("message", messageListener);
  }, [load]);

  if (error) {
    return <div className="mt-mnav md:mt-dnav p-5">{error?.message}</div>;
  }

  if (!isResolved || !sanityDocument) {
    return <div className="mt-mnav md:mt-dnav p-5">Loading...</div>;
  }

  switch (sanityDocument._type) {
    case "post":
      return <BlogPost pageContext={{ blogPost: sanityDocument }} />;
    default:
      return null;
  }
};

const Previews = () => {
  return (
    <div>
      <a
        href={typeof window !== "undefined" ? window.location.pathname : ""}
        target="_blank"
        rel="noreferrer"
        className="block w-full text-center absolute mt-3 text-sm"
      >
        <strong>this is a preview</strong>
      </a>
      <PreviewPage />
    </div>
  );
};

export default Previews;
